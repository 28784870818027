import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (from.name === 'rma-update-page' && to.name === 'rma-page') {
            return { x: 0, y: 0 };
        } else {
            return savedPosition;
        }
    }
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title(to)
    })
})

export default router
