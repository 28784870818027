const HomePage = () => import(/* webpackChunkName: "home" */ '@/views/home-page');
const DashboardPage = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard-page');
const DocumentationTagsPage = () => import('@/components/documentation/documentation-tags-page');
const DocumentationTagPage = () => import('@/components/documentation/documentation-tag-page');
const DocumentationUploadPage = () => import('@/components/documentation/documentation-upload-page');
const DocumentationAllPage = () => import('@/components/documentation/all-documentation-page');
const DocumentationsTypesPage = () => import('@/components/documentation/documentations-types-page');
const DocumentationsByTypePage = () => import('@/components/documentation/documentations-by-type-page');
const RmasPage = () => import('@/components/rma/rmas-page');
const RmaAddPage = () => import('@/components/rma/rma-add-page');
const RmaUpdatePage = () => import('@/components/rma/rma-update-page');
const RmaPage = () => import('@/components/rma/rma-page');
const RmaPrintPage = () => import('@/components/rma/rma-print-page');
const RmaLookupPage = () => import('@/components/rma/rma-lookup-page');
const RmaReportPage = () => import('@/components/rma/rma-report-page');
const RmasOutstandingRepairsPage = () => import('@/components/rma/rmas-outstanding-repairs-page');
const FirmwaresPage = () => import('@/components/firmware/firmwares-page');
const FirmwareUploadPage = () => import('@/components/firmware/firmware-upload-page');
const FirmwareDeviceTypePage = () => import('@/components/firmware/firmware-device-type-page');
const UdnDevicesPage = () => import('@/components/udn-device/udn-devices-page');
const UdnDevicesTypePage = () => import('@/components/udn-device/udn-devices-type-page');
const UdnLookupPage = () => import('@/components/udn-device/udn-lookup-page');
const UdnDevicePage = () => import('@/components/udn-device/udn-device-page');
const UdnDeviceSearchByOrderPage = () => import('@/components/udn-device/udn-device-search-by-order-page');
const UdnDeviceBulkStatusUpdatePage = () => import('@/components/udn-device/udn-device-bulk-status-update-page');
const UdnDeviceGeneratePage = () => import('@/components/udn-device/udn-device-generate-page');
const UdnDeviceLabelsGeneratePage = () => import('@/components/udn-device/udn-device-generate-labels-page');
const ClientsPage = () => import('@/components/client/clients-page');
const ClientPage = () => import('@/components/client/client-page');
const AddressesPage = () => import('@/components/address/addresses-page');
const AddressPage = () => import('@/components/address/address-page');
const AddressUpdatePage = () => import('@/components/address/address-update-page');

const FirmwareLoaderSoftwareDownloadPage = () => import('@/components/firmware-loader-software/download-page');
const LogViewerSoftwareDownloadPage = () => import('@/components/log-viewer-software/download-page');
const AcsIpConfigSoftwareDownloadPage = () => import('@/components/acs-ip-config/download-page');

const TestReportPage = () => import('@/components/tests/tests-page');

const PartNumbersPage = () => import('@/components/part-number/part-numbers-page');
const PartNumberPage = () => import('@/components/part-number/part-number-page');
const PartNumbersCreatePage = () => import('@/components/part-number/part-numbers-create-page');

const HardwareDevicesTypesPage = () => import('@/components/hardware-device/hardware-devices-types-page');
const HardwareDevicesPage = () => import('@/components/hardware-device/hardware-devices-page');
const HardwareDevicePage = () => import('@/components/hardware-device/hardware-device-page');
const HardwareDevicesLookupPage = () => import('@/components/hardware-device/hardware-devices-lookup-page');
const HardwareDevicesSearchByOrderPage = () => import('@/components/hardware-device/hardware-devices-search-by-order-page');
const StatusUpdatesSearchByOrderPage = () => import('@/components/hardware-device/status-updates-search-by-order-page');
const HardwareDevicesBulkStatusUpdatePage = () => import('@/components/hardware-device/hardware-devices-bulk-status-update-page');
const HardwareDevicesGeneratePage = () => import('@/components/hardware-device/hardware-devices-generate-page');
const HardwareDevicesLabelGeneratePage = () => import('@/components/hardware-device/hardware-devices-label-generate-page');


const FobDeletePage = () => import('@/components/tools/fob-delete-page');
const FobLookupPage = () => import('@/components/tools/fob-lookup-page');

import LoginPage from 'common/components/account/login-page';
import ForgotPassword from 'common/components/account/forgot-password-page';
import ResetPassword from 'common/components/account/reset-password-page';
import LoginLand from '../views/login-land';

const mainTitle = "KMS Product Management System";

export const routes = [
    {
        name: 'home',                               path: '/',                                                  component: HomePage,
        meta: { title: route => mainTitle }
    },

    {
        name: 'login',                              path: '/login',                                             component: LoginPage,               alias: '/account/login',
        meta: { title: route => mainTitle, fullscreen: true },                                                  props: { productName: 'pms', themeColour: '#00b0ed' },
    }, {
                                                    path: '/forgot-password',                                   component: ForgotPassword,
        meta: { title: route => mainTitle, fullscreen: true },                                                  props: { productName: 'pms', themeColour: '#00b0ed' },
    }, {
                                                    path: '/reset-password',                                    component: ResetPassword,
        meta: { title: route => mainTitle, fullscreen: true },                                                  props: { productName: 'pms', themeColour: '#00b0ed' },
    }, {
        name: 'login-land',                         path: '/login-land',                                        component: LoginLand,
        meta: { title: route => mainTitle}
    },

    {
        name: 'dashboard',                          path: '/dashboard',                                         component: DashboardPage,
        meta: { auth: true, title: route => "Dashboard | " + mainTitle }
    },

    {
        name: 'documentation-tags-page',            path: '/documentation/tags',                                component: DocumentationTagsPage,
        meta: { title: route => 'Documentation Tags | ' + mainTitle }
    }, {
        name: 'documentation-tag-page',             path: '/documentation/tag/:tag',                            component: DocumentationTagPage,
        meta: { title: route => 'Documentation Tag Page | ' + mainTitle },                                      props: true,
    }, {
        name: 'documentation-upload-page',          path: '/documentation/upload',                              component: DocumentationUploadPage,
        meta: { auth: true, title: route => 'Documentation Upload Page | ' + mainTitle }
    }, {
        name: 'all-documentation-page',             path: '/documentation',                                     component: DocumentationAllPage,
        meta: { title: route => 'All Documentation Page | ' + mainTitle }
    }, {
        name: 'documentations-types-page', path: '/documentation/type',                                         component: DocumentationsTypesPage,
        meta: { title: route => 'Documentation Types | ' + mainTitle }
    }, {
        name: 'documentations-by-type-page', path: '/documentation/type/:partNumberId',                         component: DocumentationsByTypePage,
        meta: { title: route => 'Documentations | ' + mainTitle },                                              props: true,
    },

    {
        name: 'rmas-page',                          path: '/rmas',                                              component: RmasPage,
        meta: { auth: true, title: route => 'All RMAs | ' + mainTitle }
    }, {
        name: 'rma-add-page',                       path: '/rma/add',                                           component: RmaAddPage, 
        meta: { auth: true, title: route => 'Add RMA | ' + mainTitle },                                         props: route => Object.assign({}, route.query, route.params),
    }, {
        name: 'rmas-outstanding-repairs-page',      path: '/rma/outstanding-repairs',                           component: RmasOutstandingRepairsPage,
        meta: { auth: true, title: route => 'Outstanding Repairs | ' + mainTitle }
    }, {
        name: 'rma-update-page',                    path: '/rma/:rmaId/update',                                 component: RmaUpdatePage, 
        meta: { auth: true, title: route => 'Update RMA ' + route.params.rmaId + ' | ' + mainTitle },           props: true,
    }, {
        name: 'rma-page',                           path: '/rma/:rmaId',                                        component: RmaPage, 
        meta: { auth: true, title: route => 'RMA ' + route.params.rmaId + ' | ' + mainTitle },                  props: true,
    }, {
        name: 'rma-print-page',                     path: '/rma-print/:rmaId',                                  component: RmaPrintPage, 
        meta: { auth: true, title: route => 'KMS RMA ' + route.params.rmaId },                                  props: true,
    }, {
        name: 'rma-lookup-page',                    path: '/rma-lookup',                                        component: RmaLookupPage,
        meta: { auth: true, title: route => 'RMA Lookup | ' + mainTitle }
    }, {
        name: 'rma-report-page',                    path: '/rma-report',                                        component: RmaReportPage,
        meta: { auth: true, title: route => 'RMA Report | ' + mainTitle }
    },

    {
        name: 'firmwares-page',                     path: '/firmware',                                          component: FirmwaresPage,
        meta: { auth: true, title: route => 'All Firmware | ' + mainTitle }
    }, {
        name: 'firmware-upload-page',               path: '/firmware/:deviceType/upload',                       component: FirmwareUploadPage, 
        meta: { auth: true, title: route => 'Upload ' + route.params.deviceType + ' Firmware | ' + mainTitle }, props: true,
    }, {
        name: 'firmware-device-type-page',          path: '/firmware/device-type/:deviceType',                  component: FirmwareDeviceTypePage, 
        meta: { auth: true, title: route => route.params.deviceType + ' Firmware | ' + mainTitle },             props: true,
    },

    {
        name: 'udn-devices-page',                   path: '/udn-devices',                                       component: UdnDevicesPage,
        meta: { auth: true, title: route => 'All UDN Devices | ' + mainTitle }
    }, {
        name: 'udn-device-type-page',               path: '/udn-devices/device-type/:deviceType',               component: UdnDevicesTypePage, 
        meta: { auth: true, title: route => 'All ' + route.params.deviceType + ' | ' + mainTitle },             props: true,
    }, {
        name: 'udn-lookup-page',                    path: '/udn-lookup',                                        component: UdnLookupPage,
        meta: { title: route => 'UDN Lookup | ' + mainTitle }
    }, {
        name: 'udn-device-page',                    path: '/udn/:udn',                                          component: UdnDevicePage, 
        meta: { title: route => 'UDN ' + route.params.udn + ' | ' + mainTitle },                                props: true,
    }, {
        name: 'udn-device-search-by-order-page',    path: '/udn-devices/search-by-order',                       component: UdnDeviceSearchByOrderPage,
        meta: { title: route => 'UDN ' + route.params.udn + ' | ' + mainTitle },                                props: true,
    },

    {
        name: 'udn-device-bulk-status-update-page', path: '/udn-devices/bulk-status-update',                    component: UdnDeviceBulkStatusUpdatePage,
        meta: { auth: true, title: route => 'UDN Device Bulk Status Update | ' + mainTitle }
    }, {
        name: 'udn-device-generate-page',           path: '/udn-devices/generate',                              component: UdnDeviceGeneratePage,
        meta: { auth: true, title: route => 'Generate New UDN Devices | ' + mainTitle }
    }, {
        name: 'udn-device-generate-labels-page',    path: '/udn-devices/generate-labels',                       component: UdnDeviceLabelsGeneratePage,
        meta: { auth: true, title: route => 'Generate New UDN Device Labels | ' + mainTitle },                  props: route => Object.assign({}, route.query, route.params),
    }, {
        name: 'tests-report-page',                  path: '/udn-devices/test-report',                           component: TestReportPage, 
        meta: { auth: true, title: route => 'UDN Device Test Report | ' + mainTitle },                          props: true,
    },

    {
        name: 'clients-page',                       path: '/clients',                                           component: ClientsPage,
        meta: { auth: true, title: route => 'All Clients | ' + mainTitle }
    }, {
        name: 'client-page',                        path: '/client/:clientId',                                  component: ClientPage, 
        meta: { auth: true, title: route => 'Client ' + route.params.clientId + ' | ' + mainTitle },            props: true,
    },

    {
        name: 'addresses-page',                     path: '/addresses',                                         component: AddressesPage,
        meta: { auth: true, title: route => 'All Addresses | ' + mainTitle }
    }, {
        name: 'address-page',                       path: '/address/:addressId',                                component: AddressPage, 
        meta: { auth: true, title: route => 'Address ' + route.params.addressId + ' | ' + mainTitle },          props: true,
    }, {
        name: 'address-update-page',                path: '/address/:addressId/update',                         component: AddressUpdatePage, 
        meta: { auth: true, title: route => 'Update Address ' + route.params.addressId + ' | ' + mainTitle },   props: true,
    },

    {
        name: 'fls-download-page',                  path: '/download-software',                                 component: FirmwareLoaderSoftwareDownloadPage, 
        meta: { auth: true, title: route => 'Donwload Firmware Loader Software | ' + mainTitle },               props: true,
    },
    {
        name: 'lvs-download-page',                  path: '/log-viewer-download',                               component: LogViewerSoftwareDownloadPage,
        meta: { auth: true, title: route => 'Donwload Log Viewer Software | ' + mainTitle },                    props: true,
    },

    {
        name: 'acs-ip-config-download-page', path: '/acs-ip-config-download', component: AcsIpConfigSoftwareDownloadPage,
        meta: { auth: true, title: route => 'Donwload ACS IP Config Software | ' + mainTitle }, props: true,
    },

    {
        name: 'hardware-devices-types-page',        path: '/hardware-devices/types',                            component: HardwareDevicesTypesPage,
        meta: { auth: true, title: route => 'Hardware Device Types | ' + mainTitle },
    }, {
        name: 'hardware-devices-page',              path: '/hardware-device/:partNumberId',                     component: HardwareDevicesPage,
        meta: { auth: true, title: route => 'All' + route.params.typeId + ' | ' + mainTitle },                  props: true,
    }, {
        name: 'hardware-device-page',               path: '/hw/:salesPartNumber/:serialNumber',                 component: HardwareDevicePage,
        meta: { title: route => 'Hardware Device | ' + mainTitle },                                             props: true,
    }, {
        name: 'hardware-devices-lookup-page',       path: '/hardware-devices/lookup',                           component: HardwareDevicesLookupPage,
        meta: { auth: true, title: route => 'Hardware Device Lookup | ' + mainTitle },
    }, {
        name: 'hardware-devices-search-by-order-page', path: '/hardware-devices/search-by-order', component: HardwareDevicesSearchByOrderPage,
        meta: { auth: true, title: route => 'Search by Order Number | ' + mainTitle },
    }, {
        name: 'status-updates-search-by-order-page', path: '/hardware-devices/status-updates/search-by-order', component: StatusUpdatesSearchByOrderPage,
        meta: { auth: true, title: route => 'Search by Order Number | ' + mainTitle },
    }, {
        name: 'hardware-devices-bulk-status-update-page', path: '/hardware-devices/bulk-status-update',         component: HardwareDevicesBulkStatusUpdatePage,
        meta: { auth: true, title: route => 'Bulk Hardware Status Update | ' + mainTitle },
    }, {
        name: 'hardware-devices-generate-page',     path: '/hardware-devices/generate',                         component: HardwareDevicesGeneratePage,
        meta: { auth: true, title: route => 'Generate New Hardware Devices | ' + mainTitle },
    }, {
        name: 'hardware-devices-label-generate-page', path: '/hardware-devices/label-generate',                 component: HardwareDevicesLabelGeneratePage,
        meta: { auth: true, title: route => 'Generate Hardware Labels | ' + mainTitle },                        props: true,
    },

    {
        name: 'part-numbers-page',                  path: '/part-numbers',                                      component: PartNumbersPage,
        meta: { auth: true, title: route => 'All Product Types | ' + mainTitle },                               props: true,
    }, {
        name: 'part-number-page',                   path: '/part-number/:id',                                   component: PartNumberPage,
        meta: { auth: true, title: route => 'Product Type | ' + mainTitle },                                    props: true,
    }, {
        name: 'part-numbers-create-page',           path: '/part-numbers/create',                               component: PartNumbersCreatePage,
        meta: { auth: true, title: route => 'Create Part Number | ' + mainTitle },
    }, {
        name: 'part-number-create-page',            path: '/part-number/:parentId/create',                      component: PartNumbersCreatePage,
        meta: { auth: true, title: route => 'Create Part Number | ' + mainTitle },                              props: true,
    },


    {
        name: 'fob-delete-page', path: '/tools/fob-delete', component: FobDeletePage,
        meta: { auth: true, title: route => 'Fob Delete Page | ' + mainTitle }, props: true,
    },
    {
        name: 'fob-lookup-page', path: '/tools/fob-lookup', component: FobLookupPage,
        meta: { auth: true, title: route => 'Fob Lookup Page | ' + mainTitle }, props: true,
    },
]
