<template>
    <div id="app">
        <notifications position="top center" classes="alert sk-notification mt-5" width="80%" :duration="10000">
            <template slot="body" slot-scope="props">
                <div v-bind:class="'alert sk-notification d-print-none ' + props.item.type + ' mt-3'">
                    <button type="button" class="close" @click="props.close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 v-if="props.item.title" class="alert-heading"> {{ props.item.title }} </h4>
                    <span v-html="props.item.text" />
                </div>
            </template>
        </notifications>

        <transition name="fade">
            <loading-came v-if="!$auth.ready()" :branding="true" :overlay="true"></loading-came>

            <div class="d-flex flex-column app-main-wrapper" v-if="$auth.ready()">
                <div class="sticky-top">

                    <nav class="navbar navbar-expand-lg pb-0" v-if="showBars">
                        <router-link to="/dashboard" class="navbar-brand" v-if="$auth.check('KMSUSER_USER', 'permissions')">
                            <img v-if="isACS" src="./assets/logo-acs.svg" />
                            <div v-if="isACS" class="text-muted mt-n2" style="font-size:0.875rem;">ACS Cloud</div>
                            <img v-else src="./assets/logo.svg" />
                        </router-link>
                        <router-link to="/" class="navbar-brand" v-else>
                            <img v-if="isACS" src="./assets/logo-acs.svg" />
                            <div v-if="isACS" class="text-muted mt-n2" style="font-size:0.875rem;">ACS Cloud</div>
                            <img v-else src="./assets/logo.svg" />
                        </router-link>

                        <router-link to="/dashboard" class="navbar-brand product text-light" v-if="$auth.check('KMSUSER_USER', 'permissions')">
                            Product Management System
                        </router-link>
                        <router-link to="/" class="navbar-brand product text-light" v-else>
                            Product Management System
                        </router-link>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="!$auth.check()">
                            <nav-menu params="route: route"></nav-menu>

                            <div class="ml-auto">
                                <router-link to="/dashboard" class="btn btn-outline-light btn-sm">KMS Login</router-link>
                            </div>
                        </div>
                        <div v-else class="ml-auto">
                            <div class="dropdown d-none d-lg-block">
                                <button class="btn btn-outline-light dropdown-toggle btn-sm" type="button" id="userDropdownMenuButtonLg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{  $auth.user().user.displayName }}
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdownMenuButtonLg">
                                    <a class="dropdown-item usermenu-dropdown-item" href="#" @click.prevent="logout">Logout</a>
                                </div>
                            </div>
                        </div>

                        <button class="navbar-toggler white ml-auto" type="button"
                                data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon white">&#9776;</span>
                        </button>
                    </nav>

                    <nav class="navbar navbar-expand-lg pt-0 pb-1 pb-lg-0" v-if="showBars && $auth.check()">
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <nav-menu params="route: route"></nav-menu>

                            <div v-if="$auth.check()" class="dropdown d-lg-none">
                                <button class="btn btn-outline-light dropdown-toggle btn-sm" type="button" id="userDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{  $auth.user().user.displayName }}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="userDropdownMenuButton">
                                    <a class="dropdown-item usermenu-dropdown-item" href="#" @click.prevent="logout">Logout</a>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div class="pt-0 pb-1 navbar" v-if="showBars && !$auth.check()"></div>
                </div>

                <transition name="fade">
                    <router-view></router-view>
                </transition>

                <div class="footermargin flex-grow"></div>

                <footer v-if="showBars" class="footer d-print-none">
                    <div class="container-fluid">
                        <span class="text-muted">
                            <span class="footer-hide-extra-small-screen">
                                Copyright
                            </span>
                            &copy;
                            <span class="footer-hide-extra-small-screen">
                                2022
                            </span>
                            CAME KMS
                            <span class="footer-hide-small-screen"> - All rights reserved</span>
                        </span>
                    </div>
                </footer>
            </div>

            <router-view v-else>
                Whoa, this should never be seen!
            </router-view>
        </transition>
    </div>
</template>

<script>
    require('bootstrap')

    import NavMenu from '@/components/nav-menu'
    import LoadingCame from 'common/components/loading-came'

    export default {
        components: {
            'nav-menu': NavMenu,
            'loading-came': LoadingCame,
        },

        data() {
            return {
                displayTopBar: true, // Incoming option from window.location #displaytopbar=false
                fullscreen: false, // Incoming option from route.meta.fullscreen
            }
        },

        methods: {
            logout: function () {
                this.$auth.logout({
                    makeRequest: false,
                    params: {},
                    success: () => this.$notify({ type: 'alert-success', text: 'Successfully logged out' }),
                    error: (response) => this.$notify({ type: 'alert-danger', title: 'Failed to logout', text: response.data.message }),
                    redirect: '/',
                });
            },
            debug: function (value) {
                this.$debug = value;
            },
        },

        computed: {
            showBars() {
                return this.displayTopBar && !this.fullscreen;
            }
        },

        created() {
            if (this.isACS) {
                document.documentElement.classList.add('acs');
                document.documentElement.classList.remove('skw');
            } else {
                document.documentElement.classList.add('skw');
                document.documentElement.classList.remove('acs');

            }

            document.documentElement.classList.add('light');
            document.documentElement.classList.remove('dark');
        },

        mounted() {
            if (window.location.href.indexOf("displaytopbar=false") > 0)
                this.displayTopBar = false;
        },

        watch: {
            '$route': {
                handler(to, from) {
                    this.fullscreen = (to.meta.fullscreen);
                },
                deep: true,
                immediate: true,
            }
        }
    }
</script>

<style>
    #app {
        height: 100%;
    }

    .app-main-wrapper {
        min-height: 100vh;
    }

    .navbar {
        background-color: #000000 !important;
    }

    .navbar-brand img {
        height: 30px;
        margin-top: -12px;
    }

    .navbar-brand.router-link-active {
        color: #fff;
    }

    .navbar-toggler.white {
        border: 1px solid #fff;
        color: #fff;
        line-height: normal;
        vertical-align: central;
        font-size: 1rem;
    }

    button.navbar-toggler.white:hover {
        background-color: #fff;
        color: #000;
    }

    .nav-link {
        font-family: 'came_type', -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        color: #fff;
    }

    div.dropdown {
        display: inline-block;
    }

    .main-container {
        margin-top: 30px;
    }

    .logo {
        font-family: webfont;
        font-size: 30px;
        line-height: 30px;
        margin-right: 10px;
    }

    .product {
        line-height: 30px;
        vertical-align: bottom;
        text-transform: uppercase;
        letter-spacing: 0.03rem;
        font-size: 1.5rem !important;
        font-family: 'came_type', -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 500;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-top: 0.2rem !important;
        display: inline-block;
    }

    .usermenu-dropdown-item {
        color: #333 !important;
    }

    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    .footermargin {
        min-height: 160px;
    }

    .footer {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #000;
    }

    @media(max-width: 260px) {
        .footer-hide-extra-small-screen {
            display: none
        }
    }

    @media (max-width: 400px) {
        .footer-hide-small-screen {
            display: none;
        }
    }
</style>

