<template>
    <ul class="navbar-nav mr-auto">



        <!--<li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarTagDocumentationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Documentation
        </a>

        <div class="dropdown-menu" aria-labelledby="navbarTagDocumentationDropdown">
            <router-link v-if="$auth.check()" to="/documentation" class="dropdown-item">All Documentation</router-link>
            <router-link to="/documentation/tags" class="dropdown-item">Documentation Tags</router-link>
            <router-link v-if="$auth.check()" to="/documentation/upload" class="dropdown-item">Upload Documentation</router-link>

            <div class="dropdown-divider"></div>

            <div style="max-height: 400px; overflow-x: auto;">
                <router-link v-for="documentationTag in documentationTags" v-bind:key="documentationTag" :to="'/documentation/tag/' + documentationTag" class="dropdown-item">
                    {{ documentationTag }}
                </router-link>
            </div>
        </div>
    </li>-->

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDocumentationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Documentation
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarDocumentationDropdown">
                <router-link v-if="$auth.check()" to="/documentation" class="dropdown-item">All Documentation</router-link>
                <router-link to="/documentation/type" class="dropdown-item">Documentation Types</router-link>
                <router-link to="/documentation/tags" class="dropdown-item">Documentation Tags</router-link>
                <router-link v-if="$auth.check()" to="/documentation/upload" class="dropdown-item">Upload Documentation</router-link>

                <div class="dropdown-divider"></div>

                <div style="max-height: 400px; overflow-x: auto;">
                    <router-link v-for="documentationType in documentationTypes" v-bind:key="documentationType.partNumberId" :to="'/documentation/type/' + documentationType.partNumberId" class="dropdown-item">
                        {{ documentationType.name }}
                    </router-link>

                    <div class="dropdown-divider"></div>


                    <router-link v-for="documentationTag in documentationTags" v-bind:key="documentationTag" :to="'/documentation/tag/' + documentationTag" class="dropdown-item">
                        {{ documentationTag }}
                    </router-link>
                </div>


            </div>
        </li>

        <li v-if="$auth.check()" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarFirmwareDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Firmware
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarRmaDropdown">
                <router-link to="/download-software" class="dropdown-item">Download Software</router-link>

                <div class="dropdown-divider"></div>

                <div style="max-height: 400px; overflow-x: auto;">
                    <router-link v-for="firmwareDeviceType in firmwareDeviceTypes" v-bind:key="firmwareDeviceType.deviceType" :to="'/firmware/device-type/' + firmwareDeviceType.deviceType" class="dropdown-item">
                        {{ firmwareDeviceType.name }}
                    </router-link>
                </div>
            </div>
        </li>

        <li v-if="$auth.check()" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarUdnDevicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                UDN Device
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarUdnDevicesDropdown">
                <router-link to="/udn-devices" class="dropdown-item">UDN Device Types</router-link>
                <router-link to="/udn-lookup" class="dropdown-item">UDN Lookup</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/hardware-devices/status-updates/search-by-order" class="dropdown-item">Search by Order Number</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/hardware-devices/bulk-status-update" class="dropdown-item">Bulk Status Update</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/udn-devices/generate" class="dropdown-item">Generate New UDNs</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/udn-devices/generate-labels" class="dropdown-item">Generate UDN Labels</router-link>
                <router-link v-if="$auth.check('FIRMWARE_APIACCESS', 'permissions')" to="/udn-devices/test-report" class="dropdown-item">Test Report</router-link>

                <div class="dropdown-divider"></div>

                <div style="max-height: 400px; overflow-x: auto;">
                    <router-link v-for="udnDeviceType in udnDeviceTypes" v-bind:key="udnDeviceType.deviceType" :to="'/udn-devices/device-type/' + udnDeviceType.deviceType" class="dropdown-item">
                        {{ udnDeviceType.name }}
                    </router-link>
                </div>
            </div>
        </li>

        <li v-if="$auth.check()" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarHardwareDevicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hardware Device
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarHardwareDevicesDropdown">
                <router-link to="/hardware-devices/types" class="dropdown-item">Hardware Device Types</router-link>
                <router-link to="/hardware-devices/lookup" class="dropdown-item">Hardware Device Lookup</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/hardware-devices/status-updates/search-by-order" class="dropdown-item">Search by Order Number</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/hardware-devices/bulk-status-update" class="dropdown-item">Bulk Status Update</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/hardware-devices/generate" class="dropdown-item">Generate New Hardware Devices</router-link>
                <router-link v-if="$auth.check('KMSUSER_USER', 'permissions')" to="/hardware-devices/label-generate" class="dropdown-item">Generate Hardware Labels</router-link>

                <div class="dropdown-divider"></div>

                <div style="max-height: 400px; overflow-x: auto;">
                    <router-link v-for="hardwareDeviceType in hardwareDeviceTypes" v-bind:key="hardwareDeviceType.deviceType" :to="'/hardware-device/' + hardwareDeviceType.partNumberId" class="dropdown-item">
                        {{ hardwareDeviceType.name }}
                    </router-link>
                </div>
            </div>
        </li>

        <li v-if="$auth.check('KMSUSER_USER', 'permissions')" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarRmaDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                RMA
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarRmaDropdown">
                <router-link to="/rmas" class="dropdown-item">All RMAs</router-link>
                <router-link to="/rma/add" class="dropdown-item">Add RMA</router-link>
                <router-link to="/rma-lookup" class="dropdown-item">RMA Lookup</router-link>
                <router-link to="/rma-report" class="dropdown-item">RMA Report</router-link>
                <router-link to="/rma/outstanding-repairs" class="dropdown-item">RMA Items with Outstanding Repair</router-link>
            </div>
        </li>


        <li v-if="$auth.check('KMSUSER_USER', 'permissions')" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Tools
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarToolsDropdown">
                <router-link to="/tools/fob-delete" class="dropdown-item">Fob Delete Tool</router-link>
                <router-link to="/tools/fob-lookup" class="dropdown-item">Fob Lookup Tool</router-link>

                <div class="dropdown-divider"></div>

                <router-link to="/download-software" class="dropdown-item">Download Firmware Loader Software</router-link>
                <router-link to="/log-viewer-download" class="dropdown-item">Download Log Viewer Software</router-link>
                <router-link to="/acs-ip-config-download" class="dropdown-item">Download ACS IP Config Software</router-link>

            </div>
        </li>



        <li v-if="$auth.check('KMSUSER_USER', 'permissions')" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarConfigDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Configuration
            </a>

            <div class="dropdown-menu" aria-labelledby="navbarConfigDropdown">
                <router-link to="/clients" class="dropdown-item">Clients</router-link>
                <router-link to="/addresses" class="dropdown-item">Addresses</router-link>

                <div class="dropdown-divider"></div>

                <router-link to="/part-numbers" class="dropdown-item">All Part Numbers</router-link>
            </div>
        </li>
    </ul>
</template>

<script>
    export default {
        data() {
            return {
                collapsed: false,
                documentationTags: [],
                documentationTypes: [],
                firmwareDeviceTypes: [],
                udnDeviceTypes: [],
                hardwareDeviceTypes: [],
            }
        },

        methods: {
            toggleCollapsed: function () {
                this.collapsed = !this.collapsed;
            },
            async handleAuthChange(isAuthenticatedDOESNTWORK) {
                this.documentationTags = (await this.$http.get(`/documentation/tags`)).data;
                this.documentationTypes = (await this.$http.get(`/documentation/type`)).data;

                if (this.$auth.check()) {
                    this.firmwareDeviceTypes = (await this.$http.get('/firmware/device-types')).data;
                    this.udnDeviceTypes = (await this.$http.get('/udn-device/device-types')).data;
                    this.hardwareDeviceTypes = (await this.$http.get('/part-number/hardware-device')).data;
                }
            }
        },

        async created() {
            await this.handleAuthChange(false);
            this.$watch(() => this.$auth.check(), this.handleAuthChange);
        }
    }
</script>

<style>
    .slide-enter-active, .slide-leave-active {
        transition: max-height .35s
    }

    .slide-enter, .slide-leave-to {
        max-height: 0px;
    }

    .slide-enter-to, .slide-leave {
        max-height: 20em;
    }
</style>
